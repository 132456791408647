.progress.ant-progress-circle .ant-progress-text {
  color: #ffff;
  font-size: 1.1rem;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.top-nav {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 10px;
  color: #fff;
  width: 100%;
}

.top-nav > a {
  margin: 10px;
}

.top-nav > div {
  margin-right: 10px !important;
}

.logo {
  cursor: pointer;
  margin-top: 2px;
  border-radius: 50%;
}
